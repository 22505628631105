import React from "react";
import PropTypes from "prop-types";
import { Controller, Scene } from "react-scrollmagic";
import { graphql } from "gatsby";
import SEO from "../../components/seo";
import Layout from "../../containers/layout/layout";
import Banner from "../../containers/single-area/banner";
import Heading from "../../components/shared/heading";
// import Text from "../../components/shared/text";
// import SectionTitle from "../../components/shared/section-title";
import Image from "../../components/image";
// import Area from "../../components/area";
import {
  ProjectDetailsWrap,
  ProjectHeaderWrap,
  // ProjectHeaderMeta,
  // ProjectType,
  // ProjectFeatureWrap,
  FullwidthBox,
  // NextProjectWrap
} from "./area-template.stc";

const AreaTemplate = ({
  data,
  pageContext: { next, previous },
  ...restProps
}) => {
  const { title } = restProps;
  // const { title, metaHeading, metaText, nextProjectStyle } = restProps;
  const areaData = data.areasJson;
  const { body, services, cover_image, color } = areaData;
  return (
    <Layout headerLayout={2}>
      <SEO title={areaData.title} />
      <Banner data={data.areasJson.header_image} />
      <ProjectDetailsWrap>
        <ProjectHeaderWrap className="section-ptb-xl">
          <div className="row">
            <div className="col-1 offset-1">
              <div className="rn-project-meta-inner">
                {/* {areaData.title && (
                  <Heading {...title} color={color}>
                    {areaData.title}
                  </Heading>
                )} */}
                <Image fluid={areaData.logo_image.childImageSharp.fluid} alt="logo" />
                {/* <ProjectHeaderMeta>
                  {areaData.client && (
                    <ProjectType
                      className="wow fadeInLeft"
                      data-wow-delay="200ms"
                      data-wow-duration="1000ms"
                    >
                      <Heading {...metaHeading} color={color}>
                        Client
                      </Heading>
                      <Text {...metaText}>{areaData.client}</Text>
                    </ProjectType>
                  )}
                  {areaData.sector && (
                    <ProjectType
                      className="wow fadeInLeft"
                      data-wow-delay="200ms"
                      data-wow-duration="1000ms"
                    >
                      <Heading {...metaHeading} color={color}>
                        Sector
                      </Heading>
                      <Text {...metaText}>{areaData.sector}</Text>
                    </ProjectType>
                  )}
                  {areaData.year && (
                    <ProjectType
                      className="wow fadeInLeft"
                      data-wow-delay="200ms"
                      data-wow-duration="1000ms"
                    >
                      <Heading {...metaHeading} color={color}>
                        Year
                      </Heading>
                      <Text {...metaText}>{areaData.year}</Text>
                    </ProjectType>
                  )}
                </ProjectHeaderMeta> */}
              </div>
            </div>
            <div className="col-2 offset-1">
              <div className="rn-project-content">
                {body && body.map((text, i) => <p key={`text-${i}`}>{text}</p>)}
                {services && services.map((service, i) => <li key={`service-${i}`}> {service}</li>)}
              </div>
            </div>
          </div>
        </ProjectHeaderWrap>
        {cover_image && (
          <FullwidthBox>
            <Controller>
              <Scene
                classToggle="animated"
                triggerElement="#project-image-1"
                triggerHook="onCenter"
              >
                <div className="rn_surface" id="project-image-1">
                  <Image
                    fluid={cover_image.childImageSharp.fluid}
                    alt="single"
                  />
                </div>
              </Scene>
            </Controller>
          </FullwidthBox>
        )}

        <br />
        <br />
      </ProjectDetailsWrap>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    areasJson(id: { eq: $id }) {
      id
      title
      client
      sector
      year
      body
      color
      services
      cover_image {
        childImageSharp {
          fluid(maxWidth: 1760, maxHeight: 990, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
            presentationHeight
          }
        }
      }
      header_image {
        childImageSharp {
          fluid(maxWidth: 1760, maxHeight: 990, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
            presentationHeight
          }
        }
      }
      logo_image {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
            presentationHeight
          }
        }
      }
    }
  }
`;

AreaTemplate.propTypes = {
  title: PropTypes.object,
  metaHeading: PropTypes.object,
  metaText: PropTypes.object
};

AreaTemplate.defaultProps = {
  title: {
    color: "primary",
    fontSize: "40px",
    lineHeight: "55px",
    responsive: {
      medium: {
        fontSize: "24px",
        lineHeight: "initial"
      }
    }
  },
  metaHeading: {
    as: "h6",
    color: "primary",
    fontSize: "12px",
    fontweight: 700,
    letterspacing: "2px",
    mb: "12px"
  },
  metaText: {
    m: 0,
    fontSize: "12px",
    color: "#000000",
    letterspacing: "1px"
  },
  nextProjectStyle: {
    mt: "100px",
    responsive: {
      medium: {
        mt: "60px"
      }
    }
  }
};

export default AreaTemplate;
