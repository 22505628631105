import React, { useEffect } from "react";
import styled from 'styled-components';
import Image from "../../../components/image";
import { BannerWrap } from "./banner.stc";


const Banner = (props) => {
  const bannerImg = props;

  useEffect(() => {
    console.log(bannerImg)
    return () => {

    }
  }, [])
  return (
    <BannerWrap>
      <Image fluid={bannerImg.data.childImageSharp.fluid} alt="Area Banner" />
    </BannerWrap>
  );
};

export default Banner;
